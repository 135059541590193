import { api } from '../api/api';

export const getStats = async () => {
  const token = localStorage.getItem('CiroCorporateJWTToken');

  try {
    const response = await api.get<DashboardStats>('/corporates/stats', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export type DashboardStats = {
  members: number;
  transactions: number;
  orders: number;
  balance: number;
  pendingOrders: number;
  cancelledOrders: number;
  ongoingOrders: number;
  completedOrders: number;
};

export const topupBalance = async (amount: number): Promise<any> => {
  const token = localStorage.getItem('CiroCorporateJWTToken');

  return api.post(
    '/transactions',
    { amount },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
