import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        flexDirection: 'column',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h1" fontWeight="bold">
        404
      </Typography>
      <Typography variant="h4">Page Not Found</Typography>
      <Button variant="contained" component={Link} to="/">
        Go Back
      </Button>
    </Stack>
  );
};

export default NoMatch;
