import { Button, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../../app/components/common/LoadingIndicator';
import { getOrders } from '../../../app/repositories/order';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'store',
    headerName: 'Cistore',
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Alamat',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Total Pesanan',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ value }) => {
      return <Chip label={value} color="primary" variant="outlined" />;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <Chip
          label={value}
          variant="filled"
          color={value === 'pickup' ? 'success' : 'warning'}
        />
      );
    },
  },
];

const OrdersPage = () => {
  const { data: orders, isLoading } = useQuery(['orders'], getOrders);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const rowData = orders!.map((order) => ({
    id: order.id,
    store: order.store ? order.store.name : 'Belum Ada Cistore',
    address: order.address,
    amount: order.totalAmount,
    status: order.status,
    type: order.type,
  }));

  return (
    <React.Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Typography variant="h5">Pesanan</Typography>
        <Button component={Link} to="/orders/map" variant="contained">
          Lihat Peta
        </Button>
      </Stack>
      <DataGrid
        autoHeight
        rows={rowData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </React.Fragment>
  );
};

export default OrdersPage;
