import { createTheme, ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      '100': '#ebf8fa',
      '200': '#c2e9ef',
      '300': '#99dae5',
      '400': '#71ccdb',
      '500': '#48bdd0',
      '600': '#2fa4b7',
      main: '#227785',
      '800': '#1a5b66',
      '900': '#10373d',
    },
    secondary: {
      main: '#f8b100',
      light: '#ffda80',
    },
    success: {
      main: '#1d7151',
    },
  },
};

export const theme = createTheme(themeOptions);
