import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router';
import { theme } from '../../../infrastructure/theme/theme';
import Header from './Header';
import Navigator from './Navigator';

const drawerWidth = 256;

const Dashboard = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [notifOpen, setNotifOpen] = React.useState(false);
  const [chatOpen, setChatOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  // const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNotifToggle = () => {
    setNotifOpen(!notifOpen);
  };

  const handleChatToggle = () => {
    setChatOpen(!chatOpen);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
      >
        {isSmUp ? null : (
          <Navigator
            PaperProps={{
              style: {
                width: drawerWidth,
                backgroundColor: theme.palette.primary.main,
              },
            }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        )}
        <Navigator
          PaperProps={{
            style: {
              width: drawerWidth,
              backgroundColor: theme.palette.primary.main,
            },
          }}
          sx={{ display: { sm: 'block', xs: 'none' } }}
        />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Header
          onDrawerToggle={handleDrawerToggle}
          onNotifToggle={handleNotifToggle}
          onChatToggle={handleChatToggle}
        />
        <Box component="main" sx={{ flex: 1, px: 3, py: 2 }}>
          <Outlet />
        </Box>
      </Box>
      <Drawer
        onClose={() => setNotifOpen(false)}
        anchor="right"
        open={notifOpen}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          bgcolor="primary.light"
          p={2}
          minWidth={360}
        >
          <Typography variant="h6" fontWeight="bold">
            Notifications
          </Typography>
          <IconButton onClick={() => setNotifOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        >
          <Typography textAlign="center" marginTop={10}>
            No notifications yet.
          </Typography>
          {/* <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={state?.user?.name} src={state?.user?.avatarUrl} />
            </ListItemAvatar>
            <ListItemText
              primary="Workspacing sent
              you 10 new tokens."
              secondary={
                <Typography
                  component="span"
                  variant="caption"
                  color="text.secondary"
                >
                  2 days ago
                </Typography>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" /> */}
        </List>
      </Drawer>
    </Box>
  );
};

export default Dashboard;
