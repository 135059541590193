import ArticleIcon from '@mui/icons-material/Article';
// import Icon from '@mui/icons-material/cash';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import GridOnIcon from '@mui/icons-material/GridOn';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../contexts/authContext';
import { theme } from '../../../infrastructure/theme/theme';
import { NavLink } from '../../common/NavLink';

const categories = [
  {
    id: 'Dashboard',
    icon: <GridOnIcon color="secondary" />,
    to: '/',
  },
  {
    id: 'Anggota',
    icon: <PeopleIcon color="secondary" />,
    to: '/members',
  },
  {
    id: 'Pesanan',
    icon: <SwapHorizIcon color="secondary" />,
    to: '/orders',
  },
];

const item = {
  // py: 2,
  px: 3,
  mx: 2,
  borderRadius: '20px',
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  py: 1.5,
  px: 3,
};

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {'© '}
      Ciro Waste {new Date().getFullYear()}
    </Typography>
  );
}

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;
  const navigate = useNavigate();
  const { deleteToken } = useAuth();

  const logout = () => {
    deleteToken();
    navigate('/login', { replace: true });
  };

  return (
    <Drawer
      sx={{
        borderWidth: 0,
      }}
      variant="permanent"
      {...other}
    >
      <Stack justifyContent="space-between" flex="1" pb={1}>
        <List disablePadding>
          <ListItem
            sx={{
              ...itemCategory,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Box mx="auto">
              <img width={60} src="/ciroo.png" alt={'Ciro'} loading="lazy" />
            </Box>
          </ListItem>

          {categories.map(({ id: childId, icon, to }) => (
            <ListItem
              key={childId}
              disablePadding
              sx={{
                marginTop: 1,
                borderWidth: 0,
                // paddingLeft: '16px',
                // paddingRight: '16px',
              }}
            >
              {/* @ts-ignore */}
              <ListItemButton
                component={NavLink}
                to={to}
                activeClassName="Mui-selected"
                sx={item}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText sx={{ color: grey[900] }}>{childId}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Stack spacing={2} component="footer" sx={{ p: 2 }}>
          <Button
            onClick={logout}
            startIcon={<LogoutIcon />}
            variant="contained"
            color="error"
          >
            Logout
          </Button>
          <Copyright />
        </Stack>
      </Stack>
    </Drawer>
  );
}
