import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { ReactElement } from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MoneyIcon from '@mui/icons-material/Money';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { theme } from '../../../app/infrastructure/theme/theme';
import { getOrders } from '../../../app/repositories/order';
import LoadingIndicator from '../../../app/components/common/LoadingIndicator';
import { useQuery } from 'react-query';
import { getMembers } from '../../../app/repositories/member';
import { getStats } from '../../../app/repositories/profile';
import { StatsCard } from '../../../app/components/common/StatsCard';

const orderColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'store',
    headerName: 'Cistore',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Total Pesanan',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ value }) => {
      return <Chip label={value} color="primary" variant="outlined" />;
    },
  },
];

const memberColumns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'name',
    headerName: 'Nama',
    flex: 1,
  },
  {
    field: 'balance',
    headerName: 'Saldo',
    type: 'number',
    width: 150,
  },
];

const data01 = [
  {
    name: 'Plastik PET',
    value: 400,
  },
  {
    name: 'Plastik PP',
    value: 300,
  },
  {
    name: 'Minyak Jelantah',
    value: 300,
  },
];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.25;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const HomePage = () => {
  const COLORS = [
    theme.palette.primary.light,
    theme.palette.primary.main,
    theme.palette.primary.dark,
  ];

  const { data: orders, isLoading: orderIsLoading } = useQuery(
    ['orders'],
    getOrders
  );
  const { data: members, isLoading: memberIsLoading } = useQuery(
    ['members'],
    getMembers
  );
  const { data: stats, isLoading: statsIsLoading } = useQuery(
    ['stats'],
    getStats
  );

  if (orderIsLoading || memberIsLoading || statsIsLoading) {
    return <LoadingIndicator />;
  }

  const memberRowData = members!.map((member) => ({
    id: member.id,
    name: member.name,
    balance: member.user.balance,
  }));

  const orderRowData = orders!.map((order) => ({
    id: order.id,
    store: order.store ? order.store.name : 'Belum Ada Cistore',
    address: order.address,
    amount: order.totalAmount,
    status: order.status,
    type: order.type,
  }));

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} md={4}>
        <StatsCard
          title="Total Anggota"
          data={stats!.members}
          icon={<PeopleAltIcon sx={{ fontSize: 60 }} />}
        />
      </Grid> */}
      <Grid item xs={12} md={4}>
        <StatsCard
          title="Total Transaksi"
          data={stats!.transactions}
          icon={<CallSplitIcon sx={{ fontSize: 60 }} />}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatsCard
          title="Total Saldo"
          data={stats!.balance}
          icon={<MoneyIcon sx={{ fontSize: 60 }} />}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatsCard
          title="Pesanan Belum Diambil"
          data={stats!.pendingOrders}
          icon={<MoneyIcon sx={{ fontSize: 60 }} />}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatsCard
          title="Pesanan Berjalan"
          data={stats!.ongoingOrders}
          icon={<MoneyIcon sx={{ fontSize: 60 }} />}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatsCard
          title="Pesanan Dibatalkan"
          data={stats!.cancelledOrders}
          icon={<MoneyIcon sx={{ fontSize: 60 }} />}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <StatsCard
          title="Pesanan Selesai"
          data={stats!.completedOrders}
          icon={<MoneyIcon sx={{ fontSize: 60 }} />}
        />
      </Grid>
      <Grid item xs={12} md={6} height={500} mb={4}>
        <Typography variant="h6" mb={1}>
          Pesanan Terbaru
        </Typography>
        <DataGrid
          rows={orderRowData}
          columns={orderColumns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          autoPageSize
        />
      </Grid>
      <Grid item xs={12} md={6} height={500} mb={4}>
        <Typography variant="h6" mb={1}>
          Daftar Anggota
        </Typography>
        <DataGrid
          rows={memberRowData}
          columns={memberColumns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          autoPageSize
        />
      </Grid>
    </Grid>
  );
};

export default HomePage;
