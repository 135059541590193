import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  FormHelperText,
  InputLabel,
  Stack,
} from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { topupBalance } from '../../../app/repositories/profile';

// name: string;
//   email: number;
//   password: string;
//   phone: string;
//   address: string;
//   latitude: number;
//   longitude: number;

const schema = yup.object({
  amount: yup
    .number()
    .required('Masukkan jumlah saldo yang akan ditransfer')
    .typeError('Masukkan jumlah saldo yang valid')
    .min(50000, 'Minimum jumlah saldo adalah 50000'),
});

const TopupCreditModal = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  const mutation = useMutation(
    (formData: any) => topupBalance(formData.amount),
    {
      onSuccess: (result) => {
        const win = window.open(result.data.paymentLink, '_blank');
        win && win.focus();
        reset();
        handleClose();
      },
      onError: (error: any) => {
        toast.error('Gagal melakukan topup. Coba lagi nanti');
      },
    }
  );

  return (
    <form id="topup-credit" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Button variant="outlined" onClick={handleClickOpen}>
        Topup Saldo
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Topup Saldo</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>Topup saldo anda</DialogContentText>
          <Stack spacing={3}>
            <FormControl
              error={!!errors.amount}
              size="small"
              fullWidth
              variant="filled"
            >
              <InputLabel htmlFor="amount">Jumlah</InputLabel>
              <FilledInput
                {...register('amount')}
                error={!!errors.amount}
                id="amount"
                autoFocus
              />
              {errors.amount ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.amount?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="inherit"
          >
            Batal
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            form="topup-credit"
            type="submit"
            variant="contained"
          >
            Topup
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default TopupCreditModal;
