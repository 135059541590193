import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const LoadingIndicator = () => {
  return (
    <Box flex={1} display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  );
};

export default LoadingIndicator;
