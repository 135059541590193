import axios from 'axios';

export const api = axios.create({
  // baseURL: 'http://localhost:3000/api/v1',
  baseURL: 'https://api.cirowaste.com/api/v1',
  // baseURL:
  //   process.env.NODE_ENV === 'development'
  //     ? 'http://localhost:3000/api/v1'
  //     : 'https://ciro-backend.herokuapp.com/api/v1',
  timeout: 60000,
});
