import { MoneyOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridApi,
  GridCellValue,
  GridColDef,
  GridToolbar,
} from '@mui/x-data-grid';
import React from 'react';
import { useQuery } from 'react-query';
import LoadingIndicator from '../../../app/components/common/LoadingIndicator';
import { StatsCard } from '../../../app/components/common/StatsCard';
import { useAuth } from '../../../app/contexts/authContext';
import { getMembers } from '../../../app/repositories/member';
import AddStoreMemberAccountModal from '../components/AddStoreMemberAccountModal';
import TopupCreditModal from '../components/TopupCreditModal';
import TransferCreditMemberModal from '../components/TransferCreditMemberModal';

const MembersPage = () => {
  const {
    state: { user },
  } = useAuth();
  const { data: members, isLoading } = useQuery(['members'], getMembers);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Nama',
      width: 250,
    },
    {
      field: 'address',
      headerName: 'Alamat',
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'No. HP',
      width: 150,
    },
    {
      field: 'orders',
      headerName: 'Pesanan',
      type: 'number',
      width: 150,
    },
    {
      field: 'balance',
      headerName: 'Saldo',
      type: 'number',
      width: 150,
    },
    {
      field: 'action',
      headerName: '',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        return <TransferCreditMemberModal id={Number(params.id)} />;
      },
      width: 200,
    },
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const rowData = members!.map((member) => ({
    id: member.id,
    name: member.name,
    phone: member.user.phone,
    orders: member.orders.length,
    balance: member.user.balance,
    address: member.address,
  }));

  return (
    <React.Fragment>
      <Box width={400}>
        <Card elevation={2}>
          <CardContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography gutterBottom>Saldo Saat Ini</Typography>
                <Typography
                  color="secondary.main"
                  fontWeight="bold"
                  variant="h5"
                  component="div"
                  gutterBottom
                >
                  {user?.balance}
                </Typography>
              </Box>
              <TopupCreditModal />
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
        marginTop={2}
      >
        <Typography variant="h5">Anggota</Typography>
        <AddStoreMemberAccountModal />
      </Stack>
      <DataGrid
        autoHeight
        rows={rowData}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </React.Fragment>
  );
};

export default MembersPage;
