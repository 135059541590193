import { api } from '../api/api';

export const getMembers = async () => {
  const token = localStorage.getItem('CiroCorporateJWTToken');

  try {
    const response = await api.get<MemberProfile[]>('/corporates/members', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

type MemberProfile = {
  id: string;
  avatarUrl?: string;
  address: string;
  isOpen: boolean;
  latitude: boolean;
  longitude: boolean;
  name: string;
  user: {
    name: string;
    balance: number;
    phone: string;
  };
  orders: Order[];
};

export type Order = {
  id: number;
  totalAmount: number;
  status?: 'pending' | 'ongoing' | 'completed' | 'cancelled';
  type?: 'pickup' | 'delivery';
  storeId?: number;
  address: string;
  latitude: number;
  longitude: number;
  products?: OrderItem[];
  // store?: Store;
  createdAt?: string;
};

export enum OrderStatus {
  pending = 'pending',
  ongoing = 'ongoing',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum OrderType {
  pickup = 'pickup',
  delivery = 'delivery',
}

export type OrderItem = {
  id: number;
  productId: number;
  quantity: number;
  amount: number;
  orderId: number;
  product?: Product;
};

export type ProductCategory = {
  id: number;
  name: string;
};

export type Product = {
  id: number;
  name: string;
  description: string;
  example?: string;
  images: string[];
  price: number;
  maxPrice: number;
  minPrice: number;
  category: ProductCategory;
  unit: ProductUnit;
  priceVariations: PriceVariation[];
};

export type PriceVariation = {
  id: number;
  unit: ProductUnit;
  unitId: number;
  price: number;
  quantityMin: number;
  quantityMax: number;
  createdAt: string;
  updatedAt: string;
};

export type ProductUnit = {
  id: number;
  name: string;
};

export type AddMemberBody = {
  name: string;
  email: number;
  password: string;
  phone: string;
  address: string;
  latitude: number;
  longitude: number;
};

export const addMember = async (formValues: AddMemberBody) => {
  const token = localStorage.getItem('CiroCorporateJWTToken');

  console.log(formValues);

  try {
    const response = await api.post('/corporates/members/new', formValues, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const transferCredit = async (id: number, amount: number) => {
  const token = localStorage.getItem('CiroCorporateJWTToken');

  try {
    const response = await api.post(
      `/corporates/members/${id}/transfer`,
      { amount },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
