import React, { useEffect } from 'react';
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { IconButton } from '@mui/material';
import toast from 'react-hot-toast';
import { MyLocation } from '@mui/icons-material';

// const center = {
//   lat: -3.745,
//   lng: -38.523,
// };

export type LocationAddress = {
  lat?: number;
  lng?: number;
  description?: string;
};

type GoogleMapViewProps = {
  lat?: number;
  lng?: number;
  height?: number | string;
  onChange?: ({ lat, lng, description }: LocationAddress) => void;
  readOnly?: boolean;
  markers?: { lat: number; lng: number }[];
};
interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

const GoogleMapView = ({
  lat = 0,
  lng = 0,
  height = '400px',
  onChange,
  readOnly = false,
  markers,
}: GoogleMapViewProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCykv7NPXgdJVylPFVSf2PgL_NuUjfhHRM',
    libraries: ['places'],
  });
  const [coordinates, setCoordinates] = React.useState<{
    lat: number;
    lng: number;
  }>({ lat, lng });

  // React.useEffect(() => {
  //   if (withCurrentLocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         console.log(position.coords.latitude);
  //         console.log(position.coords.longitude);
  //         setCoordinates({
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       (err) => {
  //         toast.error(err.message);
  //       }
  //     );
  //   }
  // }, []);

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds({
        lat,
        lng,
      });
      markers?.forEach((marker) => {
        bounds.extend(marker);
      });
      map.fitBounds(bounds);
      setMap(map);
    },
    [coordinates]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    myLocation();
  }, []);

  const myLocation = () => {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log(position.coords.latitude);
        console.log(position.coords.longitude);
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        onChange &&
          onChange({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
      },
      (err) => {
        toast.error(err.message);
      }
    );
  };

  return isLoaded ? (
    <>
      <GoogleMap
        options={{
          styles: customStyle,
          mapTypeControl: false,
          streetViewControl: false,
          gestureHandling: readOnly ? 'none' : 'auto',
        }}
        mapContainerStyle={{
          width: '100%',
          height,
        }}
        center={coordinates}
        zoom={13}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}

        <IconButton
          onClick={myLocation}
          sx={{
            position: 'absolute',
            left: '10px',
            bottom: '30px',
          }}
        >
          <MyLocation />
        </IconButton>
        <Marker position={coordinates} />
        {markers && markers?.length !== 0
          ? markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={
                  'https://res.cloudinary.com/rikoriswandha/image/upload/v1652826264/ciro/trash_b8xou2.png'
                }
              />
            ))
          : null}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default React.memo(GoogleMapView);

const customStyle = [
  {
    featureType: 'administrative',
    stylers: [
      {
        hue: '#000000',
      },
      {
        lightness: -100,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    stylers: [
      {
        hue: '#ff0000',
      },
      {
        saturation: '-3',
      },
      {
        lightness: '17',
      },
      {
        gamma: '1.81',
      },
      {
        visibility: 'on',
      },
      {
        weight: '0.01',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#dddddd',
      },
      {
        saturation: -100,
      },
      {
        lightness: -3,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [
      {
        hue: '#000000',
      },
      {
        saturation: -100,
      },
      {
        lightness: -100,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        hue: '#000000',
      },
      {
        saturation: -100,
      },
      {
        lightness: -100,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#bbbbbb',
      },
      {
        saturation: -100,
      },
      {
        lightness: 26,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        hue: '#ffffff',
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text',
    stylers: [
      {
        color: '#797979',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#868686',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#ff0000',
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#b6b2b2',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels',
    stylers: [
      {
        hue: '#ff0000',
      },
      {
        lightness: -100,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#ff0000',
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels',
    stylers: [
      {
        hue: '#000000',
      },
      {
        saturation: -100,
      },
      {
        lightness: -100,
      },
      {
        visibility: 'off',
      },
    ],
  },
];
