import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import GoogleMapView, {
  LocationAddress,
} from '../../../app/components/common/GoogleMapView';
import { addMember, AddMemberBody } from '../../../app/repositories/member';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';

// name: string;
//   email: number;
//   password: string;
//   phone: string;
//   address: string;
//   latitude: number;
//   longitude: number;

const schema = yup.object({
  name: yup.string().required('Nama tidak boleh kosong'),
  email: yup
    .string()
    .required('Email tidak boleh kosong')
    .email('Masukkan email yang benar'),
  password: yup.string().required('Password tidak boleh kosong'),
  phone: yup.string().required('No. HP tidak boleh kosong'),
  address: yup.string().required('Alamat tidak boleh kosong'),
  latitude: yup.number().required('Pilih lokasi Cistore'),
  longitude: yup.number().required('Pilih lokasi Cistore'),
});

const AddStoreMemberAccountModal = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  const mutation = useMutation(
    (formData: AddMemberBody) => addMember(formData),
    {
      onSuccess: () => {
        toast.success('Anggota berhasil ditambahkan');
        queryClient.invalidateQueries('members');
        reset();
        handleClose();
      },
      onError: (error: any) => {
        toast.error('Tidak dapat menambahkan anggota. Coba lagi nanti');
      },
    }
  );

  const handleLocationChange = ({ lat, lng }: LocationAddress) => {
    console.log(lat, lng);
    lat && setValue('latitude', lat.toString());
    lng && setValue('longitude', lng.toString());
  };

  return (
    <form id="new-member" onSubmit={handleSubmit(onSubmit)} noValidate>
      <Button variant="contained" onClick={handleClickOpen}>
        Tambah Anggota
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Anggota Baru</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            Buat akun anggota Cistore perusahaan anda
          </DialogContentText>
          <Stack spacing={3}>
            <FormControl
              error={!!errors.name}
              size="small"
              fullWidth
              variant="filled"
            >
              <InputLabel htmlFor="name">Nama</InputLabel>
              <FilledInput
                {...register('name')}
                error={!!errors.name}
                id="name"
                autoFocus
              />
              {errors.name ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.name?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl
              error={!!errors.email}
              size="small"
              fullWidth
              variant="filled"
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <FilledInput
                {...register('email')}
                error={!!errors.email}
                type="email"
                id="email"
                autoFocus
              />
              {errors.email ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.email?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl
              error={!!errors.password}
              size="small"
              fullWidth
              variant="filled"
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <FilledInput
                {...register('password')}
                error={!!errors.password}
                type="password"
                id="password"
                autoFocus
              />
              {errors.password ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.password?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl
              error={!!errors.phone}
              size="small"
              fullWidth
              variant="filled"
            >
              <InputLabel htmlFor="phone">No. HP</InputLabel>
              <FilledInput
                {...register('phone')}
                error={!!errors.phone}
                type="phone"
                id="phone"
                autoFocus
              />
              {errors.phone ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.phone?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl
              error={!!errors.address}
              size="small"
              fullWidth
              variant="filled"
            >
              <InputLabel htmlFor="address">Alamat</InputLabel>
              <FilledInput
                {...register('address')}
                error={!!errors.address}
                id="address"
              />
              {errors.address ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.address?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
            <FormControl
              error={!!errors.latitude || !!errors.longitude}
              variant="outlined"
              size="small"
              fullWidth
            >
              <FormLabel>Lokasi</FormLabel>
              {/* <GoogleMapsAutocomplete /> */}
              <GoogleMapView onChange={handleLocationChange} height="200px" />
              {errors.latitude || errors.longitude ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.latitude?.message ?? errors.longitude?.message}
                </FormHelperText>
              ) : null}
              {/* <Autocomplete
            apiKey='AIzaSyCykv7NPXgdJVylPFVSf2PgL_NuUjfhHRM'
            onPlaceSelected={(place: any) => {
              console.log(place);
            }}
          /> */}
              {/* <MapsAutocompleteInput /> */}
            </FormControl>

            {/* <Controller
          name="images"
          control={control}
          defaultValue={state.formValues?.images}
          rules={{
            validate: (val) => (Array.isArray(val) ? val.length > 0 : !!val),
          }}
          render={({ field: { onChange, value } }) => (
            <ProductFileUpload onChange={onChange} value={value} />
          )}
        /> */}
          </Stack>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="inherit"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            form="new-member"
            type="submit"
            variant="contained"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default AddStoreMemberAccountModal;
