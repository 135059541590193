import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { api } from '../api/api';
// import { getToken, getMessaging } from '@firebase/messaging';
// import app from '../helpers/firebase';

interface IContextProps {
  state: IState;
  setToken: (token: string) => void;
  deleteToken: () => void;
  getUser: () => Promise<void>;
  authLoaded: boolean;
}

interface IState {
  token?: string;
  isLoggedIn?: boolean;
  user?: Record<string, any>;
}

interface IActions {
  type: ActionTypes;
  payload?: any;
}

export enum ActionTypes {
  SetToken,
  DeleteToken,
  SetUser,
}

export const AuthContext = createContext({} as IContextProps);
// const messaging = getMessaging(app);

const initialState: IState = {
  token: undefined,
  isLoggedIn: undefined,
  user: undefined,
};

const reducer = (state: IState, action: IActions) => {
  console.log('Reducer', action);
  switch (action.type) {
    case ActionTypes.DeleteToken:
      return { token: undefined, isLoggedIn: false, user: undefined };
    case ActionTypes.SetToken:
      return {
        token: action.payload.token,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case ActionTypes.SetUser:
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<
    (state: IState, action: IActions) => IState
  >(reducer, initialState);
  const [authLoaded, setAuthLoaded] = useState(false);

  const checkLoginStatus = () => {
    const token = localStorage.getItem('CiroCorporateJWTToken');

    console.log(token);

    if (!!token) {
      dispatch({
        type: ActionTypes.SetToken,
        payload: {
          token,
          isLoggedIn: true,
        },
      });
    } else {
      dispatch({
        type: ActionTypes.SetToken,
        payload: {
          token: undefined,
          isLoggedIn: false,
        },
      });
    }
  };

  const getUser = async () => {
    const token = localStorage.getItem('CiroCorporateJWTToken');

    const response = await api.get('/users/me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: ActionTypes.SetUser,
      payload: {
        user: response?.data,
      },
    });
  };

  const deleteToken = () => {
    localStorage.removeItem('CiroCorporateJWTToken');

    dispatch({ type: ActionTypes.DeleteToken });
  };

  const setToken = (token: string) => {
    localStorage.setItem('CiroCorporateJWTToken', token);

    dispatch({
      type: ActionTypes.SetToken,
      payload: {
        token,
        isLoggedIn: true,
      },
    });
  };

  // const setPushToken = async () => {
  //   const token = localStorage.getItem('CiroCorporateJWTToken');

  //   getToken(messaging, {
  //     vapidKey:
  //       'BA6vpBElI23Ev2t5Ta09IFHtasHocPAtfYQJmdiCdLC4YXUeh8Q3soG9lOpkZATkq0_l0yhMdbvXwIGkq5DlsBo',
  //   })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         // Send the token to your server and update the UI if necessary
  //         // ...
  //         try {
  //           api.post(
  //             '/users/push-token',
  //             { pushToken: currentToken },
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${token}`,
  //                 'Content-Type': 'application/json',
  //               },
  //             }
  //           );
  //         } catch (error) {
  //           console.error(error);
  //         }
  //       } else {
  //         // Show permission request UI
  //         console.error(
  //           'No registration token available. Request permission to generate one.'
  //         );
  //         // ...
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('An error occurred while retrieving token. ', err);
  //       // ...
  //     });
  // };

  useEffect(() => {
    setAuthLoaded(false);
    checkLoginStatus();
    setAuthLoaded(true);
  }, []);

  useEffect(() => {
    if (!!state.isLoggedIn) {
      getUser();
      // setPushToken();
    }
  }, [state.isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{ state, setToken, deleteToken, getUser, authLoaded }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IContextProps {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
}
