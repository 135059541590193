import { Alert as MuiAlert, AlertProps, Snackbar } from '@mui/material';
import React, { useContext } from 'react';
import { useSnackbar } from '../../contexts/snackbarContext';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackbarWrapper = () => {
  const { state, hideSnackbar } = useSnackbar();

  return (
    <Snackbar
      open={state.show}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={6000}
      onClose={hideSnackbar}
    >
      <Alert
        onClose={hideSnackbar}
        severity={state.type}
        sx={{ width: '100%' }}
      >
        {state.body}
      </Alert>
    </Snackbar>
  );
};
