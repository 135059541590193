import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import '../App.css';
import { AppRouter } from './components/common/AppRouter';
import { SnackbarWrapper } from './components/common/SnackbarWrapper';

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <SnackbarWrapper />
    </React.Fragment>
  );
}

export default App;
