import { api } from '../api/api';

export const getOrders = async () => {
  const token = localStorage.getItem('CiroCorporateJWTToken');

  try {
    const response = await api.get<Order[]>('/corporates/orders', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export type Order = {
  id: number;
  totalAmount: number;
  status?: 'pending' | 'ongoing' | 'completed' | 'cancelled';
  type?: 'pickup' | 'delivery';
  storeId?: number;
  address: string;
  latitude: number;
  longitude: number;
  products?: OrderItem[];
  store?: Store;
  createdAt?: string;
};

export enum OrderStatus {
  pending = 'pending',
  ongoing = 'ongoing',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum OrderType {
  pickup = 'pickup',
  delivery = 'delivery',
}

export type OrderItem = {
  id: number;
  productId: number;
  quantity: number;
  amount: number;
  orderId: number;
  product?: Product;
};

export type Store = {
  id: number;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
  avatarUrl: string;
  isOpen: boolean;
  isActive: boolean;
};

export type ProductCategory = {
  id: number;
  name: string;
};

export type Product = {
  id: number;
  name: string;
  description: string;
  example?: string;
  images: string[];
  price: number;
  category: ProductCategory;
  unit: ProductUnit;
};

export type ProductUnit = {
  id: number;
  name: string;
};
