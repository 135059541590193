import { Card, CardContent, Stack, Box, Typography } from '@mui/material';
import { ReactElement } from 'react';

export type StatsCardProps = {
  title: string;
  data: string | number;
  icon: ReactElement;
};

export const StatsCard = (props: StatsCardProps) => {
  return (
    <Card elevation={2}>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography gutterBottom>{props.title}</Typography>
            <Typography
              color="secondary.main"
              fontWeight="bold"
              variant="h5"
              component="div"
              gutterBottom
            >
              {props.data}
            </Typography>
          </Box>
          {/* {props.icon} */}
        </Stack>
      </CardContent>
    </Card>
  );
};
