import React, { createContext, useContext, useReducer } from 'react';

interface IContextProps {
  state: IState;
  showSnackbar: (args: Omit<IState, 'show'>) => void;
  hideSnackbar: () => void;
}

export enum SnackbarType {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

interface IState {
  show: boolean;
  type?: SnackbarType;
  title?: string;
  body?: string;
}

interface IActions {
  type: ActionTypes;
  payload?: any;
}

export enum ActionTypes {
  ShowSnackbar,
  HideSnackbar,
}

export const SnackbarContext = createContext({} as IContextProps);

const initialState = {
  show: false,
};

const reducer = (state: IState, action: IActions) => {
  switch (action.type) {
    case ActionTypes.ShowSnackbar:
      return {
        show: true,
        type: action.payload.type,
        title: action.payload.title,
        body: action.payload.body,
      };
    case ActionTypes.HideSnackbar:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

export const SnackbarContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<
    (state: IState, action: IActions) => IState
  >(reducer, initialState);

  const showSnackbar = ({ type, title, body }: Omit<IState, 'show'>) => {
    dispatch({
      type: ActionTypes.ShowSnackbar,
      payload: {
        type,
        title,
        body,
      },
    });
    setTimeout(() => {
      hideSnackbar();
    }, 5000);
  };

  const hideSnackbar = () => {
    dispatch({ type: ActionTypes.HideSnackbar });
  };

  return (
    <SnackbarContext.Provider value={{ state, hideSnackbar, showSnackbar }}>
      {props.children}
    </SnackbarContext.Provider>
  );
};

export function useSnackbar(): IContextProps {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      'useSnackbar must be used within an SnackbarContextProvider'
    );
  }
  return context;
}
