import React, { createContext, useContext, useReducer } from 'react';

interface IContextProps {
  state: IState;
  showDialog: (args: Omit<IState, 'show'>) => void;
  hideDialog: () => void;
}

interface IState {
  show: boolean;
  type?: string;
  title?: string;
  content?: string;
  actionText?: string;
  onActionPress?: () => void;
  props?: Record<string, any>;
}

interface IActions {
  type: ActionTypes;
  payload?: any;
}

export enum ActionTypes {
  ShowDialog,
  HideDialog,
}

export const DialogContext = createContext({} as IContextProps);

const initialState = {
  show: false,
};

const reducer = (state: IState, action: IActions) => {
  switch (action.type) {
    case ActionTypes.ShowDialog:
      return {
        show: true,
        type: action.payload.type,
        title: action.payload.title,
        content: action.payload.content,
        actionText: action.payload.actionText,
        onActionPress: action.payload.onActionPress,
        props: action.payload.props,
      };
    case ActionTypes.HideDialog:
      return { ...state, show: false };
    default:
      return state;
  }
};

export const DialogContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<
    (state: IState, action: IActions) => IState
  >(reducer, initialState);

  const showDialog = ({
    type,
    title,
    content,
    actionText,
    onActionPress,
    props,
  }: Omit<IState, 'show'>) => {
    dispatch({
      type: ActionTypes.ShowDialog,
      payload: {
        type,
        title,
        content,
        actionText,
        onActionPress,
        props,
      },
    });
  };

  const hideDialog = () => {
    dispatch({ type: ActionTypes.HideDialog });
  };

  return (
    <DialogContext.Provider value={{ state, hideDialog, showDialog }}>
      {props.children}
    </DialogContext.Provider>
  );
};

export function useDialog(): IContextProps {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within an DialogContextProvider');
  }
  return context;
}
