import { Button, Chip, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import GoogleMapView from '../../../app/components/common/GoogleMapView';
import LoadingIndicator from '../../../app/components/common/LoadingIndicator';
import { getOrders } from '../../../app/repositories/order';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'store',
    headerName: 'Cistore',
    flex: 1,
  },
  {
    field: 'address',
    headerName: 'Alamat',
    flex: 1,
  },
  {
    field: 'amount',
    headerName: 'Total Pesanan',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ value }) => {
      return <Chip label={value} color="primary" variant="outlined" />;
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    renderCell: ({ value }) => {
      return (
        <Chip
          label={value}
          variant="filled"
          color={value === 'pickup' ? 'success' : 'warning'}
        />
      );
    },
  },
];

const OrdersMapPage = () => {
  const { data: orders, isLoading } = useQuery(['orders'], getOrders);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  console.log(orders);

  return (
    <React.Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <Typography variant="h5">Peta Pesanan</Typography>
      </Stack>
      <GoogleMapView
        height="90%"
        readOnly
        markers={orders?.map((order) => ({
          lat: order.latitude,
          lng: order.longitude,
        }))}
      />
    </React.Fragment>
  );
};

export default OrdersMapPage;
