import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
  Link as MuiLink,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useToggle } from '../../../app/hooks/useToggle';
import { theme } from '../../../app/infrastructure/theme/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { login, LoginBody } from '../../../app/repositories/auth';
import { useAuth } from '../../../app/contexts/authContext';
import {
  SnackbarType,
  useSnackbar,
} from '../../../app/contexts/snackbarContext';
import { LoadingButton } from '@mui/lab';

const schema = yup.object({
  email: yup
    .string()
    .required('Email tidak boleh kosong')
    .email('Masukkan alamat email yang valid'),
  password: yup.string().required('Password tidak boleh kosong'),
});

const LoginPage = () => {
  const [passwordObscure, togglePasswordObscure] = useToggle(true);
  const navigate = useNavigate();
  const location: any = useLocation();
  const { setToken } = useAuth();
  const { showSnackbar } = useSnackbar();

  const from = location.state?.from?.pathname || '/';
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const loginMutation = useMutation((formData: LoginBody) => login(formData), {
    onSuccess: (result) => {
      showSnackbar({ type: SnackbarType.Success, body: 'Login Success!' });
      setToken(result.data.accessToken);
      navigate(from, { replace: true });
    },
    onError: (error: any) => {
      console.log(error.response.data);
      showSnackbar({
        type: SnackbarType.Error,
        body:
          error.response?.data?.message || 'Error logging in, please try again',
      });
    },
  });

  const onSubmit = (data: any) => {
    loginMutation.mutate(data);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper elevation={5} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction={{ xs: 'column', md: 'row' }} padding={3} spacing={3}>
          {/* <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img
              width="90%"
              src="/login.png"
              alt={'Workspacing'}
              loading="lazy"
            />
          </Box> */}
          <Box sx={{ flex: 1 }}>
            <Stack alignItems="center" padding={3} spacing={3}>
              <Box>
                <img
                  width={300}
                  src="/ciro-color.png"
                  alt={'Ciro'}
                  loading="lazy"
                />
                <Typography align="center">
                  Belum punya akun?{' '}
                  <MuiLink
                    color="secondary.main"
                    href="mailto:contact@cirowaste.com"
                  >
                    Kontak kami
                  </MuiLink>
                </Typography>
              </Box>

              <FormControl
                size="small"
                fullWidth
                sx={{ m: 1 }}
                variant="outlined"
                error={!!errors.email}
              >
                <InputLabel htmlFor="email">Email</InputLabel>
                <OutlinedInput
                  {...register('email')}
                  error={!!errors.email}
                  autoFocus
                  id="email"
                  type="email"
                  label="Password"
                />
                {errors.email ? (
                  <FormHelperText sx={{ marginX: 0 }}>
                    {errors.email?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <FormControl
                size="small"
                fullWidth
                sx={{ m: 1 }}
                variant="outlined"
                error={!!errors.password}
              >
                <InputLabel htmlFor="password">Password</InputLabel>
                <OutlinedInput
                  {...register('password')}
                  error={!!errors.password}
                  id="password"
                  type={passwordObscure ? 'password' : 'text'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordObscure}
                        edge="end"
                      >
                        {passwordObscure ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {errors.password ? (
                  <FormHelperText sx={{ marginX: 0 }}>
                    {errors.password?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
              {/* <Typography alignSelf="flex-end">
                <MuiLink
                  color="secondary.main"
                  component={Link}
                  to="/forgot-password"
                >
                  Lupa password?
                </MuiLink>
              </Typography> */}
              <LoadingButton
                loading={loginMutation.isLoading}
                fullWidth
                variant="contained"
                type="submit"
              >
                Login
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
};

export default LoginPage;
