import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  AppBar,
  Avatar,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { useAuth } from '../../../contexts/authContext';
import { theme } from '../../../infrastructure/theme/theme';

const lightColor = 'rgba(255, 255, 255, 0.7)';

type HeaderProps = {
  onDrawerToggle: () => void;
  onNotifToggle: () => void;
  onChatToggle: () => void;
  title?: string;
  actions?: ReactNode;
};

export default function Header(props: HeaderProps) {
  const { onDrawerToggle } = props;
  const { state } = useAuth();

  return (
    <React.Fragment>
      <AppBar
        sx={{ bgcolor: theme.palette.primary.dark }}
        position="sticky"
        elevation={1}
      >
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item>
              <Tooltip title="Notifications">
                <IconButton onClick={props.onNotifToggle} color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton color="inherit" sx={{ p: 0.5 }}>
                <Avatar alt={state?.user?.name} src={state?.user?.avatarUrl} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {props.title ? (
        <AppBar
          component="div"
          color="transparent"
          position="static"
          elevation={0}
          sx={{ zIndex: 0 }}
        >
          <Toolbar>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs>
                <Typography color="inherit" variant="h5" component="h1">
                  {props.title}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      ) : null}
    </React.Fragment>
  );
}
