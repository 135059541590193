import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FilledInput,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import GoogleMapView, {
  LocationAddress,
} from '../../../app/components/common/GoogleMapView';
import {
  addMember,
  AddMemberBody,
  transferCredit,
} from '../../../app/repositories/member';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';

// name: string;
//   email: number;
//   password: string;
//   phone: string;
//   address: string;
//   latitude: number;
//   longitude: number;

const schema = yup.object({
  amount: yup
    .number()
    .required('Masukkan jumlah saldo yang akan ditransfer')
    .typeError('Masukkan jumlah saldo yang valid')
    .min(50000, 'Minimum jumlah saldo adalah 50000'),
});

type TransferCreditMemberModalProps = {
  id: number;
};

const TransferCreditMemberModal = ({ id }: TransferCreditMemberModalProps) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const queryClient = useQueryClient();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data: any) => {
    mutation.mutate(data);
  };

  const mutation = useMutation(
    (formData: any) => transferCredit(id, formData.amount),
    {
      onSuccess: () => {
        toast.success('Transfer berhasil');
        queryClient.invalidateQueries('members');
        reset();
        handleClose();
      },
      onError: (error: any) => {
        toast.error('Gagal saat melakukan transfer. Coba lagi nanti');
      },
    }
  );

  return (
    <form
      id={`transfer-credit-${id}`}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Button variant="outlined" onClick={handleClickOpen}>
        Transfer Saldo
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Transfer Saldo</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            Transfer saldo yang anda miliki ke Anggota anda
          </DialogContentText>
          <Stack spacing={3}>
            <FormControl
              error={!!errors.amount}
              size="small"
              fullWidth
              variant="filled"
            >
              <InputLabel htmlFor="amount">Jumlah</InputLabel>
              <FilledInput
                {...register('amount')}
                error={!!errors.amount}
                id="amount"
                autoFocus
              />
              {errors.amount ? (
                <FormHelperText sx={{ marginX: 0 }}>
                  {errors.amount?.message}
                </FormHelperText>
              ) : null}
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions style={{ padding: 24 }}>
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="inherit"
          >
            Batal
          </Button>
          <LoadingButton
            loading={mutation.isLoading}
            form={`transfer-credit-${id}`}
            type="submit"
            variant="contained"
          >
            Transfer
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default TransferCreditMemberModal;
