import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import HomePage from '../../../features/home/pages/HomePage';
import LoginPage from '../../../features/login/pages/LoginPage';
import MembersPage from '../../../features/members/pages/MembersPage';
import OrdersMapPage from '../../../features/orders/pages/OrdersMapPage';
import OrdersPage from '../../../features/orders/pages/OrdersPage';
import { useAuth } from '../../contexts/authContext';
import Dashboard from '../layouts/dashboard/Dashboard';

// import { AuthContext } from '../../contexts/authContext';
// import Dashboard from '../layouts/dashboard/Dashboard';
import NoMatch from './NoMatch';
import { RequireAuth } from './RequireAuth';
// import { ProtectedRoute } from './ProtectedRoute';

export const AppRouter = () => {
  const location = useLocation();
  let { from } = (location.state as any) || { from: { pathname: '/' } };
  const { state } = useAuth();

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route index element={<HomePage />} />
        <Route path="members" element={<MembersPage />} />
        <Route path="orders" element={<OrdersPage />} />
        <Route path="orders/map" element={<OrdersMapPage />} />
        {/* <Route path="products" element={<ProductsPage />} />
        <Route path="products/new" element={<NewProductPage />} />
        <Route path="banners" element={<BannersPage />} />
        <Route path="articles" element={<ArticlesPage />} />
        <Route path="articles/new" element={<NewArticlePage />} /> */}
      </Route>

      {/* <Route path="/products">
        <Dashboard
          headerTitle="Products"
          headerActions={
            <Button component={Link} to="/products/new">
              New Product
            </Button>
          }
        >
          <ProductsPage />
        </Dashboard>
      </Route> */}
      {/* <Route  path="/products/new">
        <Dashboard headerTitle="New Product">
          <NewProductPage />
        </Dashboard>
      </Route> */}
      {/* <Route path="/orders">
        <Dashboard headerTitle="Orders">
          <OrdersPage />
        </Dashboard>
      </Route>
      <Route path="/banners">
        <Dashboard headerTitle="Promo Banners">
          <BannersPage />
        </Dashboard>
      </Route>
      <Route path="/articles">
        <Dashboard headerTitle="Articles">
          <ArticlesPage />
        </Dashboard>
      </Route> */}
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};
