import { api } from '../api/api';

export interface LoginBody {
  email: string;
  password: string;
}

export const login = async (formData: LoginBody) => {
  return api.post('/auth/login', formData, {
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      isCorporate: true,
    },
  });
};
