import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import './index.css';
import App from './app/App';
import { QueryClient, QueryClientProvider } from 'react-query';
import reportWebVitals from './reportWebVitals';
import { theme } from './app/infrastructure/theme/theme';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SnackbarContextProvider } from './app/contexts/snackbarContext';
import { DialogContextProvider } from './app/contexts/dialogContext';
import { AuthContextProvider } from './app/contexts/authContext';
import { Toaster } from 'react-hot-toast';

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <SnackbarContextProvider>
          <DialogContextProvider>
            <QueryClientProvider client={queryClient}>
              <Toaster />
              <CssBaseline />
              <App />
            </QueryClientProvider>
          </DialogContextProvider>
        </SnackbarContextProvider>
      </ThemeProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
